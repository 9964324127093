import * as React from "react";

import StandardizedTestComponent from "../components/use_case/StandardizedTest";
import Layout from "../components/layout/layout";
import Seo from "../components/seo";

const StandardizedTest = () => {
  return (
    <>
      <Seo
        title="Test d’admission"
        description="Des solutions adaptées pour conduire vos tests standardisés à grande échelle."
      />
      <Layout>
        <StandardizedTestComponent />
      </Layout>
    </>
  );
};

export default StandardizedTest;
