import React from "react";

import Ready from "../ready/ready";
// import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Exam from "../exam/exam";
import Latest from "../latest/latest";
// import { Link } from "gatsby"
import UseCaseTopBanner from "../top_banner/product_top_banner";
import Charts from "../charts/charts";
import Logos from "../logos-section/logos";
import { useTranslation } from 'react-i18next';

const StandardizedTest = () => {
  const { t } = useTranslation();
  const exams = [
    {
      title: `${t('an_ultra_secure_online_solution')}`,
      description: `${t('do_not_sacrifice_the_image_of_your')}`,
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/infinity_with_peoples_inside.svg",
    },
    {
      title: `${t('recruit_more_recruit_better')}`,
      description: `${t('with_mereos_go_beyond_geographical')}`,
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/graph_board.svg",
    },
    {
      title: `${t('a_5_star_candidate_experience')}`,
      description: `${t('support_available_24_a_modern_solution')}`,
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/five_stars_screen_with_people.svg",
    },
    {
      title: `${t('technology_at_your_service')}`,
      description: `${t('with_our_suite_of_apis')}`,
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/worldwide_product.png",
    },
  ];

  const ChartData = {
    title: [`${t('eductive_uses_mereos_to_recruit_future')}`],
    contexte: `${t('eductive_is_a_private_higher_education_network')}`,
    Résultats: [
      {
        heading: `3 ${t('days')}`,
        desc: `${t('recruit_a_candidate_compared')}`,
      },
      {
        heading: "45%",
        desc: `${t('reduction_in_cost_per_session_on_average')}`
      },
      {
        heading: "4,7",
        desc: `${t('average_score_given_by_candidates')}`,
      },
    ],
    points: [`${t('monitoring')}`, `${t('flexibility')}`, `${t('reputation')}`, `${t('autonomy')}`],
  };

  const LatestData = {
    title1: "Découvrez notre ",
    title2: "dernière publication",
    description: `${t('why_is_a_good_candidate_experience')}`,
    btnText: `${t('learn_more')}`,
  };

  // const breakpoints = useBreakpoint();
  return (
    <div className={`conatiner font-sans`}>
      <UseCaseTopBanner
        pageTitle={t('admission_test')}
        title={t('stand_out_in_a_competitive_market')}
        description={t('the_reputation_of_a_higher_education_establishment')}
        imageSrc="https://d2lxkizvrhu4im.cloudfront.net/images/people_standing_infront_scoreboard.svg"
        rightArrowImageSrc="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow_right_white_icon.svg"
        leftButton={{
          text: `${t("free_trials")}`,
          href: "/free-trial",
        }}
        rightButton={{
          text: `Demo >`,
          href: "/demo",
        }}
        bannerType="useCase"
      />

      {/* logos  */}
      <Logos />

      <div className={`mx-auto w-11/12 lg:w-5/6 `}>
        <div className="my-20">
          <Exam Data={exams} title={t('why_is_mereos_right_for_you')} />
        </div>
      </div>

      <div className={`mx-auto w-11/12 lg:w-5/6`}>
        {/* Latest from Source */}
        <Latest data={LatestData} />

        {/* Charts */}
        <Charts ChartData={ChartData} />
      </div>

      {/* Ready */}
      <div className="flex flex-row mt-10 mb-20">
        <img
          alt="right star"
          src="https://d2lxkizvrhu4im.cloudfront.net/icons/left_star_icon.svg"
          className="hidden md:block self-end"
        />
        <div className="mt-10 mx-auto w-11/12 lg:w-5/6">
          <Ready />
        </div>

        <img
          alt="left star"
          src="https://d2lxkizvrhu4im.cloudfront.net/icons/left_star_icon.svg"
          className="hidden md:block self-start"
        />
      </div>
    </div>
  );
};

export default StandardizedTest;
